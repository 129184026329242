import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { genericResponse } from 'src/app/shared/models/generics';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TransaccionesService {
  constructor(protected _http: HttpClient, private _authService: AuthService) {}

  getTransacciones(): Observable<any> {
    // console.log('color', color);
    return this._http
      .get(`${environment.WebApiUrlCRM}/api/transaccionProspectoAuto`)
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  getContadoresGenerales(): Observable<any> {
    return this._http
      .get(`${environment.WebApiUrlCRM}/api/contadoresgenerales`)
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  getAutomovil(): Observable<any> {
    return this._http
      .get(`${environment.WebApiUrlCRM}/api/automovil`)
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  getTransaccionesID(): Observable<any> {
    // console.log('color', color);
    return this._http
      .get(`${environment.WebApiUrlCRM}/api/transaccionProspectoAuto/9ca9bc8c-0d32-4922-ba72-a70efb9f00cc-202234125520`)
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  // Nuevas APIS para transacciones

  // Listado de transacciones
  getTransaccionesByPage(
    pageSize: string,
    field: string,
    button: string = '',
    field_value: string = ''
  ): Observable<any> {
    return this._http
      .get(
        `${environment.API_URL_MANAGEMENT}/cars/getCarsByPage?&pageSize=${pageSize}&field=${field}&button=${button}&field_value=${field_value}`
      )
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  /**
   * Obtener contadores de transacciones
   * @returns
   */
  getCarsByStageCounter(): Observable<any> {
    return this._http
      .get(`${environment.API_URL_MANAGEMENT}/cars/getCarsByStageCounter`)
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  // Busqueda Transacciones
  BusquedaTransaccion(param: string): Observable<genericResponse> {
    return this._http
      .get(`${environment.API_URL_MANAGEMENT}/searchUsuario?search=${param}&backend=azure`)
      .pipe(map((response: any) => <genericResponse>response));
  }

  ///cars/getCarsByStage?pageSize=10&field=date_string&stage=registrado&field_value=2022-04-22T17:27:35.194Z&button=previous

  getTransaccionesEtapas(
    pageSize: string,
    field: string,
    stage: string,
    field_value: string = '',
    button: string = ''
  ): Observable<any> {
    return this._http
      .get(
        `${environment.API_URL_MANAGEMENT}/cars/getCarsByStage?&pageSize=${pageSize}&field=${field}&stage=${stage}&field_value=${field_value}&button=${button}`
      )
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  //  ///cars/transaction/getTransactionByStage/1?stage=ado&limit=8
  getTransaccionesEtapas2(page: number, pageSize: number, stage: string): Observable<any> {
    return this._http
      .get(
        `${environment.API_URL_MANAGEMENT}/cars/transaction/getTransactionByStage/${page}?stage=${stage}&limit=${pageSize}`
      )
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  detalleTransaccion2(idAutomovil: string, agency: string): Observable<genericResponse> {
    return this._http
      .get(`${environment.API_URL_MANAGEMENT}/cars/car/gettransaction/detail/${idAutomovil}?isFromAgency=${agency}`)
      .pipe(map((response: any) => <genericResponse>response));
  }

  //citas agendadas
  citasagendadasdeldia(idAutomovil: string): Observable<genericResponse> {
    return this._http
      .get(`${environment.API_URL_MANAGEMENT}/citasagendadasdeldia?backend=azure`)
      .pipe(map((response: any) => <genericResponse>response));
  }

  /**
   * Sirve para mostrar el listado de citas posibles para entrega
   * @param idAutomovil
   * @returns
   */
  citasEntrega(idAutomovil: string): Observable<genericResponse> {
    return this._http
      .get(`${environment.API_URL_MANAGEMENT}/citas/automovil/${idAutomovil}?backend=azure`)
      .pipe(map((response: any) => <genericResponse>response));
  }

  /*
  @busquedaParam Parametro/criterio de busqueda
  */
  searchTransacciones(
    busquedaParam: string,
    pagina_seleccionada: number,
    elementos_pagina: number
  ): Observable<genericResponse> {
    return this._http
      .get(
        `${environment.API_URL_MANAGEMENT}/searchTransacciones/${busquedaParam}?backend=azure&pagina_seleccionada=${pagina_seleccionada}&elementos_pagina=${elementos_pagina} `
      )
      .pipe(map((response: any) => <genericResponse>response));
  }

  sendComments(idAutomovil: string, body: any): Observable<any> {
    return this._http
      .post(`${environment.API_URL_MANAGEMENT}/comments/send/${idAutomovil}`, body)
      .pipe(map((response: any) => <genericResponse>response));
  }

  /**
   * Nuevo API listado y busqueda de transacciones
   * @param busquedaParam
   * @param pagina_seleccionada
   * @param elementos_pagina
   * @returns
   */
  listarTransacciones(stage: string, pagina_seleccionada: number, elementos_pagina: number, busquedaParam: string) {
    return this._http.get(
      `${environment.API_URL_MANAGEMENT}/cars/transactions/getAllTransactions?page=${pagina_seleccionada}&pageSize=${elementos_pagina}&criteria=${busquedaParam}&stage=${stage}`
    );
  }

  /**
   * Regresa el listado de documentos del auto por Id de usuario
   * @param id_usuario_automovil = correo electronico
   * @returns
   */
  listarDocumentos(idAutomovil: string): Observable<any> {
    return this._http
      .get(
        `${environment.API_URL_MANAGEMENT}/mesacontrol/documento-usuario?backend=azure-crm&idAutomovil=${idAutomovil}`
      )
      .pipe(map((response: any) => <genericResponse>response));
  }

  getTransactionByStage(stage: string, page: number, pageSize: number, criteria: string) {
    return this._http.get(
      `${environment.API_URL_MANAGEMENT}/cars/transaction/getTransactionByStage/${page}?stage=${stage}&limit=${pageSize}`
    );
  }
}
